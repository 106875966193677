import React from "react"

import SEO from "../components/seo"
import LayoutInfo from "../components/layout-info"

import imgFacebook from "./../images/Facebookliten.jpg"
import ImageCarousel from "../components/image-carousel"

class AboutPage extends React.Component {

    render() {

        return (
            <LayoutInfo>
                <SEO title="Om Leirskoledagboken"

                    description="Informasjon om Leirskoledagboken AS"
                />

                <div className="row">
                    <div className="col">
                        <h1 className="card-title">Leirskole - Ta vare på leirskolestemningen!</h1>
                        <div>
                            <p>
                                Det er stas for deres unge håpefulle å kunne fortelle om hvordan det var på leirskolen. Kanskje første gangen de har vært uten kontakt med mor og far så mange dager? Mye skjer på ei uke når man er på leirskole! Leirskoledagboken forankrer minnene. Kanskje bilder av da de store barrierer ble brutt? Første fisk man fanget? Hvem bodde de sammen med? Hva het leirskolelærene? Kanskje vise hvem de har blitt venner med fra andre skoler eller klassekamerater og kontaktlærer i en annen setting enn det vanlige? Det heter seg at et bilde sier mer enn tusen ord! Vi samler bilder fra leirskoleoppholdet sånn at dere kan glede dere over å «ta del» i leirskoleuka sammen med deres sønn eller datter.
                        </p>
                            <p>
                                Vi samarbeider med leirskoler over hele landet og presenterer leirskoleoppholdet i bokform med bilder og tekster. Bøkene kan bli inntil 72 sider avhengig av bildematerialet, trykket på papir av god kvalitet, i A4 format og koster 419,- inkludert porto. Bestilte bøker har 30 dagers fri retur slik at man kan bla fysisk gjennom boken før man bestemmer seg.
                            </p>
                        </div>

                        <div>
                            <ImageCarousel ></ImageCarousel>
                        </div>
                        <div>
                            <p>
                                <a href="/">Bestill din leirskoledagbok til fritt gjennomsyn her!</a>
                            </p>
                            <p>
                                Har du spørsmål? Ta kontakt <a href="mailto:ingar@leirskoledagboken.no">ingar@leirskoledagboken.no</a>.
                            </p>
                            <p>
                                Dersom du har vært på leirskole og har tatt egne bilder som kan bidra til en enda bedre leirskoledagbok, kan du laste dem opp
                                <a href="https://fovea.digitalpigeon.com/rcv/send" target="_blank" rel="noopener noreferrer" > her innen 3 dager etter oppholdet.</a>
                            </p>

                            <blockquote className="blockquote">
                                <p>Et opphold på leirskole skaper varige minner, Leirskoledagboken hjelper oss til at minnene blir enda lettere å hente fram igjen.</p>
                                <footer className="blockquote-footer">Ole Dagfinn Brenna <cite title="Source Title">, rektor på Dombås Fjellskole</cite></footer>
                            </blockquote>

                        </div>
                        <div>
                            <span>Følg oss på Facebook </span>
                            <a href="https://www.facebook.com/Leirskoledagboken/" target="_blank" rel="noopener noreferrer" alt="Følg oss på facebook">
                                <img className="" src={imgFacebook} alt="Følg oss på facebook"  />
                            </a>

                        </div>
                    </div>
                </div>
            </LayoutInfo>
        )
    }

}
export default AboutPage
