import React from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../styles.scss"
import carStyles from "./image-carousel.module.scss"

import img1 from "./../images/about/slide1.jpg"
import img2 from "./../images/about/slide2.jpg"
import img3 from "./../images/about/slide3.jpg"
import img4 from "./../images/about/slide4.jpg"
import img5 from "./../images/about/slide5.jpg"
import img6 from "./../images/about/slide6.jpg"
import img7 from "./../images/about/slide7.jpg"
import img8 from "./../images/about/slide8.jpg"
import img9 from "./../images/about/slide9.jpg"
import img10 from "./../images/about/slide10.jpg"
import img11 from "./../images/about/slide11.jpg"
import img12 from "./../images/about/slide12.jpg"
import img14 from "./../images/about/slide14.jpg"

// Docs https://react-slick.neostack.com/docs/api
const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    
    initialSlide: 0,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: true,
            slidesToShow: 1,
          }
        }
      ]
};

const ImageCarousel = () => (

    <div >
        <Slider {...settings}>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img1}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img2}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img3}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img4}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img5}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img6}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img7}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img8}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img9}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img10}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img11}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img12}
                    alt=""></img>
            </div>
            <div>
                <img
                    className={`${carStyles.carImage} d-block `}
                    src={img14}
                    alt=""></img>
            </div>

        </Slider>
        <br />
    </div>

)

export default ImageCarousel
